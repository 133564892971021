define("ui/components/page-header/component", ["exports", "ui/components/page-header/template", "shared/utils/constants", "shared/utils/navigation-tree", "jquery"], function (_exports, _template, _constants, _navigationTree, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function fnOrValue(val, ctx) {
    if (typeof val === 'function') {
      return val.call(ctx);
    } else {
      return val;
    }
  }

  var _default = Ember.Component.extend({
    // Injections
    intl: Ember.inject.service(),
    scope: Ember.inject.service(),
    features: Ember.inject.service(),
    settings: Ember.inject.service(),
    access: Ember.inject.service(),
    prefs: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    // Inputs
    pageScope: null,
    // Component options
    tagName: 'header',
    classNames: ['page-header'],
    dropdownSelector: '.navbar .dropdown',
    stacks: null,
    // This computed property generates the active list of choices to display
    navTree: null,
    clusterId: Ember.computed.alias('scope.currentCluster.id'),
    cluster: Ember.computed.alias('scope.currentCluster'),
    projectId: Ember.computed.alias('scope.currentProject.id'),
    project: Ember.computed.alias('scope.currentProject'),
    accessEnabled: Ember.computed.alias('access.enabled'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.get(this, 'intl.locale');
      Ember.setProperties(this, {
        stacks: Ember.get(this, 'store').all('stack'),
        hosts: Ember.get(this, 'store').all('host'),
        stackSchema: Ember.get(this, 'store').getById('schema', 'stack')
      });
      Ember.run.once(this, 'updateNavTree');
      Ember.run.scheduleOnce('render', function () {
        // responsive nav 63-87
        var responsiveNav = document.getElementById('js-responsive-nav');
        var toggleBtn = document.createElement('a');
        toggleBtn.setAttribute('class', 'nav-toggle');
        responsiveNav.insertBefore(toggleBtn, responsiveNav.firstChild);

        function hasClass(e, t) {
          return new RegExp(" ".concat(t, " ")).test(" ".concat(e.className, " "));
        }

        function toggleClass(e, t) {
          var n = " ".concat(e.className.replace(/[\t\r\n]/g, ' '), " ");

          if (hasClass(e, t)) {
            while (n.indexOf(" ".concat(t, " ")) >= 0) {
              n = n.replace(" ".concat(t, " "), ' ');
            }

            e.className = n.replace(/^\s+|\s+$/g, '');
          } else {
            e.className += " ".concat(t);
          }
        }

        toggleBtn.onclick = function () {
          toggleClass(this.parentNode, 'nav-open');
        };

        var root = document.documentElement;
        root.className = "".concat(root.className, " js");
      });
    },
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.get('router').on('routeWillChange', function () {
          (0, _jquery.default)('header > nav').removeClass('nav-open'); // eslint-disable-line
        });
      });
    },
    shouldUpdateNavTree: Ember.observer('pageScope', 'clusterId', 'cluster.isReady', 'projectId', 'stacks.@each.group', "prefs.".concat(_constants.default.PREFS.ACCESS_WARNING), 'access.enabled', 'intl.locale', function () {
      Ember.run.scheduleOnce('afterRender', this, 'updateNavTree');
    }),
    // beyond things listed in "Inputs"
    hasProject: Ember.computed('project', function () {
      return !!Ember.get(this, 'project');
    }),
    // Hackery: You're an owner if you can write to the 'system' field of a stack
    isOwner: Ember.computed('stackSchema.resourceFields.system.update', function () {
      return !!Ember.get(this, 'stackSchema.resourceFields.system.update');
    }),
    dashboardBaseLink: Ember.computed('scope.dashboardBase', function () {
      return Ember.get(this, 'scope.dashboardBase').replace(/\/+$/, '');
    }),
    dashboardLink: Ember.computed('pageScope', 'clusterId', 'cluster.isReady', function () {
      if (Ember.get(this, 'pageScope') === 'global' || !this.clusterId) {
        // Only inside a cluster
        return;
      }

      var cluster = Ember.get(this, 'cluster');

      if (!cluster || !cluster.isReady) {
        // Only in ready/active clusters
        return;
      }

      return Ember.get(this, 'scope.dashboardLink');
    }),
    updateNavTree: function updateNavTree() {
      var _this = this;

      var currentScope = Ember.get(this, 'pageScope');
      var out = (0, _navigationTree.get)().filter(function (item) {
        if (typeof Ember.get(item, 'condition') === 'function') {
          if (!item.condition.call(_this)) {
            return false;
          }
        }

        if (Ember.get(item, 'scope') && Ember.get(item, 'scope') !== currentScope) {
          return false;
        }

        var itemRoute = fnOrValue(Ember.get(item, 'route'), _this);
        var itemContext = (Ember.get(item, 'ctx') || []).map(function (prop) {
          return fnOrValue(prop, _this);
        });
        Ember.setProperties(item, {
          localizedLabel: fnOrValue(Ember.get(item, 'localizedLabel'), _this),
          label: fnOrValue(Ember.get(item, 'label'), _this),
          route: itemRoute,
          ctx: itemContext,
          submenu: fnOrValue(Ember.get(item, 'submenu'), _this)
        });
        Ember.set(item, 'submenu', (Ember.get(item, 'submenu') || []).filter(function (subitem) {
          if (typeof Ember.get(subitem, 'condition') === 'function' && !subitem.condition.call(_this)) {
            return false;
          }

          var subItemRoute = fnOrValue(Ember.get(subitem, 'route'), _this);
          var subItemContext = (Ember.get(subitem, 'ctx') || []).map(function (prop) {
            return fnOrValue(prop, _this);
          });
          Ember.setProperties(subitem, {
            localizedLabel: fnOrValue(Ember.get(subitem, 'localizedLabel'), _this),
            label: fnOrValue(Ember.get(subitem, 'label'), _this),
            route: subItemRoute,
            ctx: subItemContext
          });
          return true;
        }));
        return true;
      });
      var old = JSON.stringify(Ember.get(this, 'navTree'));
      var neu = JSON.stringify(out);

      if (old !== neu) {
        Ember.set(this, 'navTree', out);
      }
    },
    keyUp: function keyUp(e) {
      var code = e.keyCode;
      var tabList = (0, _jquery.default)(".nav-item a:first-of-type");
      var $target = (0, _jquery.default)(e.target).hasClass('ember-basic-dropdown-trigger') ? (0, _jquery.default)(e.target).find('a') : e.target;
      var currentFocusIndex = tabList.index($target);
      var nextIndex;

      switch (code) {
        case 37:
          {
            // left
            nextIndex = currentFocusIndex - 1;

            if (nextIndex >= tabList.length) {
              tabList.eq(tabList.length).focus();
            } else {
              if (tabList.eq(nextIndex).parent().hasClass('ember-basic-dropdown-trigger')) {
                tabList.eq(nextIndex).parent().focus();
              } else {
                tabList.eq(nextIndex).focus();
              }
            }

            break;
          }

        case 39:
          {
            // right
            nextIndex = currentFocusIndex + 1;

            if (nextIndex >= tabList.length) {
              tabList.eq(0).focus();
            } else {
              if (tabList.eq(nextIndex).parent().hasClass('ember-basic-dropdown-trigger')) {
                tabList.eq(nextIndex).parent().focus();
              } else {
                tabList.eq(nextIndex).focus();
              }
            }

            break;
          }

        default:
      }
    }
  });

  _exports.default = _default;
});