define("ui/authenticated/cluster/notifier/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "skmUHm2/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"scope\",\"currentCluster\",\"isReady\"]]],null,{\"statements\":[[4,\"banner-message\",null,[[\"color\"],[\"bg-warning\"]],{\"statements\":[[0,\"    \"],[7,\"p\",true],[8],[1,[28,\"t\",[\"banner.notifiers\"],[[\"dashboardLink\",\"docsBase\",\"htmlSafe\"],[[24,[\"scope\",\"dashboardLink\"]],[24,[\"scope\",\"docsBase\"]],true]]],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"section\",true],[10,\"class\",\"header clearfix\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pull-left pt-10\"],[8],[7,\"h1\",true],[8],[1,[28,\"t\",[\"notifierPage.notifiers\"],null],false],[9],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right-buttons\"],[8],[0,\"\\n    \"],[7,\"button\",false],[12,\"class\",\"btn btn-sm bg-primary\"],[3,\"action\",[[23,0,[]],\"showNewEditModal\"]],[8],[1,[28,\"t\",[\"notifierPage.index.addActionLabel\"],null],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[1,[28,\"notifier/notifier-table\",null,[[\"model\"],[[24,[\"notifiers\"]]]]],false]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/authenticated/cluster/notifier/index/template.hbs"
    }
  });

  _exports.default = _default;
});