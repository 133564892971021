define("ui/components/page-header-project/component", ["exports", "ui/components/page-header-project/template", "shared/mixins/throttled-resize", "shared/utils/text-width", "shared/utils/util", "jquery"], function (_exports, _template, _throttledResize, _textWidth, _util, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && Symbol.iterator in Object(iter)) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var ITEM_HEIGHT = 50;
  var BUFFER_HEIGHT = 150;
  var BUFFER_WIDTH = 150;
  var MIN_COLUMN_WIDTH = 200;
  var FONT = '13.5px "Prompt", "Helvetica Neue Light", "Helvetica Neue", "Helvetica", "Arial", sans-serif';
  var MOUSE_HISTORY = 5;
  var MOUSE_DELAY = 250;
  var SLOP = 50; // Extend the ends of the target triangle out by this many px

  var _default = Ember.Component.extend(_throttledResize.default, {
    access: Ember.inject.service(),
    scope: Ember.inject.service(),
    globalStore: Ember.inject.service(),
    router: Ember.inject.service(),
    layout: _template.default,
    pageScope: null,
    tagName: 'LI',
    classNames: ['dropdown', 'nav-item', 'nav-cluster'],
    classNameBindings: ['hide'],
    searchInput: '',
    open: false,
    columnStyle: '',
    menuStyle: '',
    mousePoints: null,
    clusterEntry: null,
    activeClusterEntry: null,
    hoverEntry: null,
    hoverDelayTimer: null,
    delayPoint: null,
    leaveDelayTimer: null,
    boundClickMenu: null,
    boundClickItem: null,
    boundEnterCluster: null,
    dropdownApi: null,
    project: Ember.computed.alias('scope.pendingProject'),
    cluster: Ember.computed.alias('scope.pendingCluster'),
    numClusters: Ember.computed.alias('byCluster.length'),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.setProperties(this, {
        mousePoints: [],
        boundMouseMove: this.mouseMoved.bind(this),
        boundClickMenu: this.clickMenu.bind(this),
        boundClickItem: this.clickItem.bind(this),
        boundEnterCluster: this.enterCluster.bind(this),
        boundEnterScrollers: this.enterScrollers.bind(this),
        boundLeaveScrollers: this.leaveScrollers.bind(this)
      });
    },
    actions: {
      onOpen: function onOpen(dropdown) {
        var _this = this;

        Ember.set(this, 'open', true);
        this.onResize();

        if (dropdown) {
          Ember.set(this, 'dropdownApi', dropdown);
        }

        Ember.run.next(function () {
          if (!_this.isTransitioning()) {
            var menu = (0, _jquery.default)('.project-menu');
            var clusters = (0, _jquery.default)('.clusters');
            (0, _jquery.default)(document).on('mousemove', _this.boundMouseMove);
            menu.on('click', _this.boundClickMenu);
            menu.on('click', 'LI', _this.boundClickItem);
            menu.on('touchend', '.projects LI', _this.boundClickItem);
            clusters.on('focus', 'LI', _this.boundEnterCluster);
            clusters.on('mouseenter', 'LI', _this.boundEnterCluster);
            (0, _jquery.default)('.clusters, .projects').on('mouseenter', _this.boundEnterScrollers);
            (0, _jquery.default)('.clusters, .projects').on('mouseleave', _this.boundLeaveScrollers);
            (0, _jquery.default)('.search INPUT')[0].focus();
            (0, _jquery.default)('.clusters UL')[0].scrollTop = 0;
            (0, _jquery.default)('.projects UL')[0].scrollTop = 0;
            var currentClusterId = Ember.get(_this, 'cluster.id');
            var currentProjectId = Ember.get(_this, 'project.id');

            if (currentClusterId) {
              var li = (0, _jquery.default)(".clusters LI[data-cluster-id=\"".concat(currentClusterId, "\"]"))[0];
              var entry = Ember.get(_this, 'byCluster').findBy('clusterId', currentClusterId);
              ensureVisible(li);
              Ember.setProperties(_this, {
                clusterEntry: entry,
                activeClusterEntry: entry
              });
            }

            if (currentProjectId) {
              Ember.run.next(function () {
                var li = (0, _jquery.default)(".projects LI[data-project-id=\"".concat(currentProjectId, "\"]"))[0];
                ensureVisible(li);
              });
            }
          }
        });
      },
      onClose: function onClose() {
        Ember.setProperties(this, {
          activeClusterEntry: null,
          clusterEntry: null,
          dropdownApi: null,
          hoverEntry: null,
          open: false,
          searchInput: ''
        });
        (0, _jquery.default)(document).off('mousemove', this.boundMouseMove);
        (0, _jquery.default)('.project-menu').off('click', this.boundClickMenu);
        (0, _jquery.default)('.project-menu').off('click', 'LI', this.boundClickItem);
        (0, _jquery.default)('.project-menu').off('touchend', '.projects LI', this.boundClickItem);
        (0, _jquery.default)('.clusters').off('mouseenter', 'LI', this.boundEnterCluster);
        (0, _jquery.default)('.clusters, .projects').off('mouseenter', this.boundEnterScrollers);
        (0, _jquery.default)('.clusters, .projects').off('mouseleave', this.boundLeaveScrollers);
      }
    },
    twoLine: Ember.computed('pageScope', function () {
      return Ember.get(this, 'pageScope') === 'project';
    }),
    hide: Ember.computed('pageScope', function () {
      return Ember.get(this, 'pageScope') === 'user';
    }),
    projectChoices: Ember.computed('scope.allProjects.@each.{id,displayName,relevantState}', function () {
      return Ember.get(this, 'scope.allProjects').sortBy('displayName', 'id');
    }),
    maxProjects: Ember.computed('byCluster.@each.numProjects', function () {
      var counts = Ember.get(this, 'byCluster').map(function (x) {
        return x.projects.length;
      });
      return Math.max.apply(Math, _toConsumableArray(counts));
    }),
    byCluster: Ember.computed('scope.allClusters.@each.id', 'projectChoices.@each.clusterId', 'cluster.id', function () {
      var currentClusterId = Ember.get(this, 'cluster.id');
      var out = [];
      var navWidth = (0, _jquery.default)('#application nav').width();
      Ember.get(this, 'scope.allClusters').forEach(function (cluster) {
        getOrAddCluster(cluster);
      });
      Ember.get(this, 'projectChoices').forEach(function (project) {
        var cluster = Ember.get(project, 'cluster');
        var width = getMaxWidth((0, _textWidth.default)(Ember.get(project, 'displayName'), FONT), navWidth);

        if (!cluster) {
          return;
        }

        var entry = getOrAddCluster(cluster);
        entry.projects.push(project);
        entry.projectWidth = Math.max(entry.projectWidth, width);
      });
      out.forEach(function (entry) {
        entry.projects = entry.projects.sortBy('sortName');
      });
      return out.sortBy('cluster.sortName');

      function getOrAddCluster(cluster) {
        var clusterId = Ember.get(cluster, 'id');
        var entry = out.findBy('clusterId', clusterId);
        var width = getMaxWidth((0, _textWidth.default)(Ember.get(cluster, 'displayName'), FONT), navWidth);

        if (!entry) {
          entry = {
            clusterId: clusterId,
            cluster: cluster,
            width: width,
            projectWidth: 0,
            projects: [],
            active: clusterId === currentClusterId
          };
          out.push(entry);
        }

        return entry;
      }

      function getMaxWidth(width, navWidth) {
        return width >= navWidth / 2 ? navWidth / 2 : width;
      }
    }),
    clustersWidth: Ember.computed('byCluster.@each.width', function () {
      var widths = Ember.get(this, 'byCluster').map(function (x) {
        return Ember.get(x, 'width');
      });
      return Math.max.apply(Math, _toConsumableArray(widths));
    }),
    projectsWidth: Ember.computed('byCluster.@each.projectWidth', function () {
      var widths = Ember.get(this, 'byCluster').map(function (x) {
        return Ember.get(x, 'projectWidth');
      });
      return Math.max.apply(Math, _toConsumableArray(widths));
    }),
    clusterSearchResults: Ember.computed('searchInput', 'byCluster.[]', function () {
      var needle = Ember.get(this, 'searchInput');
      var out = [];
      Ember.get(this, 'byCluster').forEach(function (entry) {
        var cluster = Ember.get(entry, 'cluster');
        var name = Ember.get(cluster, 'displayName');

        var _highlightMatches = highlightMatches(needle, name),
            found = _highlightMatches.found,
            match = _highlightMatches.match;

        if (found) {
          out.push({
            cluster: cluster,
            searchMatch: match
          });
        }
      });
      return out;
    }),
    projectSearchResults: Ember.computed('searchInput', 'byCluster.[]', function () {
      var needle = Ember.get(this, 'searchInput');
      var out = [];
      Ember.get(this, 'projectChoices').forEach(function (project) {
        var name = Ember.get(project, 'displayName');

        var _highlightMatches2 = highlightMatches(needle, name),
            found = _highlightMatches2.found,
            match = _highlightMatches2.match;

        if (found) {
          out.push({
            project: project,
            cluster: Ember.get(project, 'cluster'),
            searchMatch: match
          });
        }
      });
      return out;
    }),
    keyUp: function keyUp(e) {
      if (!Ember.isEmpty(this.dropdownApi)) {
        var project = 'project';
        var cluster = 'cluster';
        var code = e.keyCode;
        var clusterTabList = (0, _jquery.default)('.project-menu .clusters a:first-of-type');
        var projectTabList = (0, _jquery.default)('.project-menu .projects a:first-of-type');
        var tabList = [];
        var $target = (0, _jquery.default)(e.target).hasClass('ember-basic-dropdown-trigger') ? (0, _jquery.default)(e.target).find('a') : e.target;
        var currentFocusIndex;
        var currentFocus;
        var nextIndex;
        var activeClusterNode;
        var clusterEntry = this.clusterEntry;

        if (clusterEntry) {
          activeClusterNode = (0, _jquery.default)(".project-menu [data-cluster-id=\"".concat(clusterEntry.clusterId, "\"]"));
        }

        if (clusterTabList.index($target) >= 0) {
          currentFocus = cluster;
          currentFocusIndex = clusterTabList.index($target);
          tabList = clusterTabList;
        } else if (projectTabList.index($target) >= 0) {
          currentFocus = project;
          currentFocusIndex = projectTabList.index($target);
          tabList = projectTabList;
        } else {
          // from inputFocused
          currentFocus = cluster;
          currentFocusIndex = 0;
        }

        switch (code) {
          case 37:
            {
              // left
              if (currentFocus === project) {
                activeClusterNode.find('a:first-of-type').focus();
              }

              break;
            }

          case 38:
            {
              // up
              nextIndex = currentFocusIndex - 1;

              if (tabList && nextIndex >= tabList.length) {
                tabList.eq(tabList.length).focus();
              } else {
                tabList.eq(nextIndex).focus();
              }

              break;
            }

          case 39:
            {
              // right
              if (currentFocus === cluster && projectTabList.length >= 1) {
                projectTabList.eq(0).focus();
              }

              break;
            }

          case 40:
            {
              // down
              nextIndex = currentFocusIndex + 1;

              if (nextIndex >= tabList.length) {
                tabList.eq(tabList.length).focus();
              } else {
                tabList.eq(nextIndex).focus();
              }

              break;
            }

          default:
        }
      }

      return false;
    },
    mouseMoved: function mouseMoved(e) {
      var list = this.mousePoints;
      var x = e.pageX;
      var y = e.pageY;

      if (list.length) {
        var last = list[list.length - 1];

        if (last.x === x && last.y === y) {
          // Not a movement
          return;
        }
      }

      if (list.length >= MOUSE_HISTORY) {
        list.shift();
      }

      list.push({
        x: x,
        y: y
      });
    },
    clickMenu: function clickMenu(e) {
      if (e.target.tagName === 'INPUT') {
        e.stopPropagation();
        return;
      }
    },
    clickItem: function clickItem(e) {
      var _this2 = this;

      var tag = e.target.tagName;
      var li = (0, _jquery.default)(e.target).closest('LI');

      if (!li) {
        return;
      }

      if (li.hasClass('not-ready')) {
        e.stopPropagation();
        e.preventDefault();
        return;
      }

      if (tag === 'A' && e.type !== 'touchend') {
        return;
      }

      var a = (0, _jquery.default)('A', li)[0];

      if (!a) {
        return;
      }

      Ember.run.next(function () {
        _this2.send('onClose');

        a.click();
      });
    },
    enterCluster: function enterCluster(e) {
      if (Ember.get(this, 'searchInput')) {
        return;
      }

      clearTimeout(this.hoverDelayTimer);
      var $li = (0, _jquery.default)(e.target).closest('LI');
      var id = $li.data('cluster-id');

      if (id) {
        var entry = Ember.get(this, 'byCluster').findBy('clusterId', id);
        this.maybeHover(entry);
      }
    },
    enterScrollers: function enterScrollers() {
      clearTimeout(this.leaveDelayTimer);
    },
    leaveScrollers: function leaveScrollers() {
      var _this3 = this;

      clearTimeout(this.hoverDelayTimer);
      Ember.set(this, 'leaveDelayTimer', setTimeout(function () {
        Ember.setProperties(_this3, {
          hoverEntry: null,
          clusterEntry: Ember.get(_this3, 'activeClusterEntry')
        });
      }, MOUSE_DELAY));
    },
    getHoverDelay: function getHoverDelay() {
      var entry = Ember.get(this, 'activeClusterEntry');
      var points = this.mousePoints;
      var $menu = (0, _jquery.default)('.clusters');

      if (!entry) {
        // console.log('No entry');
        return 0;
      }

      if (!points.length) {
        // console.log('No points');
        return 0;
      }

      var prev = points[0];
      var now = points[points.length - 1]; // Bounding box of the menu

      var offset = $menu.offset();
      var left = offset.left;
      var top = offset.top - SLOP;
      var right = left + $menu.outerWidth();
      var bottom = offset.top + $menu.outerHeight() + SLOP;
      var dp = this.delayPoint;

      if (dp && dp.x === now.x && dp.y === now.y) {
        // The mouse hasn't moved during the delay
        // console.log('No movement');
        return 0;
      }

      if (now.x < prev.x) {
        // The mouse is moving left
        // console.log('Moving left');
        return 0;
      }

      var nowSlope = slope(prev, now);
      var topSlope = slope(prev, {
        x: right,
        y: top
      }); // negative; 0,0 is top-left

      var botSlope = slope(prev, {
        x: right,
        y: bottom
      }); // positive

      var noMove = prev.x === now.x && prev.y === now.y;
      var topOk = nowSlope >= topSlope;
      var botOk = nowSlope <= botSlope;

      if (noMove || topOk && botOk) {
        // Moving towards submenu
        this.delayPoint = now; // console.log('Ok');

        return MOUSE_DELAY;
      } // console.log('Default');


      this.delayPoint = null;
      return 0;
    },
    maybeHover: function maybeHover(entry) {
      var _this4 = this;

      clearTimeout(this.hoverDelayTimer);
      var delay = this.getHoverDelay();

      if (delay) {
        this.hoverDelayTimer = setTimeout(function () {
          _this4.maybeHover(entry);
        }, delay);
      } else {
        var prev = Ember.get(this, 'hoverEntry');

        if (entry !== prev) {
          Ember.setProperties(this, {
            hoverEntry: entry,
            clusterEntry: entry
          });
          var scrollToId;
          entry.projects.forEach(function (project) {
            if (project.active) {
              scrollToId = project.id;
            }
          });

          if (scrollToId) {
            Ember.run.next(function () {
              var li = (0, _jquery.default)(".projects LI[data-project-id=\"".concat(scrollToId, "\"]"))[0];
              ensureVisible(li);
            });
          }
        }
      }
    },
    onResize: function onResize() {
      if (!Ember.get(this, 'open')) {
        return;
      }

      var $window = (0, _jquery.default)(window);
      var want = Math.max(Ember.get(this, 'numClusters'), Ember.get(this, 'maxProjects'));
      var roomFor = Math.ceil(($window.height() - BUFFER_HEIGHT) / (2 * ITEM_HEIGHT));
      var rows = Math.max(3, Math.min(want, roomFor));
      var height = rows * ITEM_HEIGHT;
      Ember.set(this, 'columnStyle', "height: ".concat(height, "px").htmlSafe());
      var cw = Math.max(MIN_COLUMN_WIDTH, Ember.get(this, 'clustersWidth') + 60); // 20px icon, 20px padding, 20px scrollbar

      var pw = Math.max(MIN_COLUMN_WIDTH, Ember.get(this, 'projectsWidth') + 60);
      want = cw + pw;
      roomFor = $window.width() - BUFFER_WIDTH;

      if (want > roomFor) {
        cw = Math.floor(cw * roomFor / want);
        pw = roomFor - cw;
      }

      Ember.set(this, 'menuStyle', "grid-template-columns: ".concat(cw, "px ").concat(pw, "px").htmlSafe());
    },
    isTransitioning: function isTransitioning() {
      if (this.router._routerMicrolib.activeTransition && this.router._routerMicrolib.activeTransition.isTransition) {
        return true;
      }

      return false;
    }
  });

  _exports.default = _default;

  function highlightMatches(needle, haystack) {
    // This is more complicated than it sounds because:
    // - Needle matches case-insensitive, but the return string should preseve the original haystack case
    // - The haystack has to be HTML escaped
    // - But the HTML entities like &lt; shouldn't appear as search results for "lt"
    // - And we're adding HTML to highlight the matches which needs to not be escaped
    //
    var placeholder = '~';
    var match;
    var found = false;
    var parts = [];
    needle = (needle || '').trim();
    haystack = (haystack || '').trim(); // 1. If there's any occurrences of the placeholder in the string already, drop them.

    haystack = haystack.replace(placeholder, '', 'g');
    var re = new RegExp((0, _util.escapeRegex)(needle), 'i'); // 2. Find and save all matches for the needle and replace with placeholder

    /* eslint-disable-next-line no-cond-assign */

    while (match = haystack.match(re)) {
      found = true;
      parts.push(match[0]);
      haystack = haystack.replace(re, placeholder);
    }

    if (!found) {
      return {
        found: found
      };
    } // 3. Escape the resulting string of unmatched chars and placeholders


    haystack = (0, _util.escapeHtml)(haystack);

    while (parts.length) {
      var token = parts.shift(); // 4. Replace placeholders with (unescaped) highlight span and (escaped) matched chars

      haystack = haystack.replace(placeholder, "<span class=\"search-match\">".concat((0, _util.escapeHtml)(token), "</span>"));
    } // 5. Return as a safe string


    return {
      found: found,
      match: haystack.htmlSafe()
    };
  }

  function slope(a, b) {
    return round((b.y - a.y) / (b.x - a.x));
  }

  function round(n) {
    return Math.round(n * 10000) / 10000;
  }

  function ensureVisible(li) {
    var $li = (0, _jquery.default)(li);
    var $ul = $li.closest('UL');
    var ul = $ul[0];

    if (!ul) {
      return;
    }

    var ulTop = $ul.scrollTop();
    var ulBottom = ulTop + $ul.outerHeight();
    var offset = $li.offset();

    if (!offset) {
      return;
    }

    var liTop = offset.top;
    var liBottom = liTop + $li.outerHeight(); // console.log(`${ulTop} to ${ulBottom}, ${liTop} to ${liBottom}`);

    if (liTop < ulTop || liBottom > ulBottom) {
      ul.scrollTop = Math.max(0, liTop - (liBottom - liTop) / 2 + (ulBottom - ulTop) / 2);
    }
  }
});