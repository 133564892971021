define("ui/components/modal-edit-namespace/component", ["exports", "shared/mixins/new-or-edit", "shared/mixins/modal-base", "ui/components/modal-edit-namespace/template"], function (_exports, _newOrEdit, _modalBase, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var ISTIO_INJECTION = 'istio-injection';
  var ENABLED = 'enabled';

  var _default = Ember.Component.extend(_modalBase.default, _newOrEdit.default, {
    scope: Ember.inject.service(),
    layout: _template.default,
    classNames: ['large-modal'],
    editing: true,
    model: null,
    allNamespaces: null,
    allProjects: null,
    tags: null,
    beforeSaveModel: null,
    initAutoInjectionStatus: null,
    originalModel: Ember.computed.alias('modalService.modalOpts'),
    init: function init() {
      this._super.apply(this, arguments);

      var orig = Ember.get(this, 'originalModel');
      var clone = orig.clone();
      delete clone.services;
      Ember.setProperties(this, {
        model: clone,
        tags: (Ember.get(this, 'primaryResource.tags') || []).join(','),
        allNamespaces: Ember.get(this, 'clusterStore').all('namespace'),
        allProjects: Ember.get(this, 'globalStore').all('project').filterBy('clusterId', Ember.get(this, 'scope.currentCluster.id'))
      });
      var labels = Ember.get(this, 'primaryResource.labels');
      var enabled = labels && labels[ISTIO_INJECTION] === ENABLED;
      Ember.setProperties(this, {
        istioInjection: enabled,
        initAutoInjectionStatus: enabled
      });
    },
    actions: {
      addTag: function addTag(tag) {
        var tags = Ember.get(this, 'primaryResource.tags') || [];
        tags.addObject(tag);
        Ember.set(this, 'tags', tags.join(','));
      },
      updateNsQuota: function updateNsQuota(quota) {
        if (quota) {
          Ember.set(this, 'primaryResource.resourceQuota', {
            limit: quota
          });
        } else {
          Ember.set(this, 'primaryResource.resourceQuota', null);
        }
      },
      updateContainerDefault: function updateContainerDefault(limit) {
        Ember.set(this, 'primaryResource.containerDefaultResourceLimit', limit);
      },
      setLabels: function setLabels(labels) {
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });
        Ember.set(this, 'primaryResource.labels', out);
      },
      toggleAutoInject: function toggleAutoInject() {
        Ember.set(this, 'istioInjection', !Ember.get(this, 'istioInjection'));
      }
    },
    projectDidChange: Ember.observer('primaryResource.project.id', function () {
      var _this = this;

      Ember.set(this, 'switchingProject', true);
      Ember.run.next(function () {
        Ember.set(_this, 'switchingProject', false);
      });

      if (!Ember.get(this, 'primaryResource.project.resourceQuota')) {
        Ember.set(this, 'primaryResource.resourceQuota', null);
      }
    }),
    tagsDidChanged: Ember.observer('tags', function () {
      Ember.set(this, 'primaryResource.tags', Ember.get(this, 'tags').split(',') || []);
    }),
    canMoveNamespace: Ember.computed('primaryResource.actionLinks.{move}', function () {
      return !!Ember.get(this, 'primaryResource.actionLinks.move');
    }),
    projectLimit: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.limit');
    }),
    projectUsedLimit: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'resourceQuota.usedLimit');
    }),
    nsDefaultQuota: Ember.computed('primaryResource.resourceQuota.{limit}', 'primaryResource.projectId', function () {
      var projectId = Ember.get(this, 'primaryResource.projectId');
      var project = Ember.get(this, 'allProjects').findBy('id', projectId);
      return Ember.get(project, 'namespaceDefaultResourceQuota.limit');
    }),
    validate: function validate() {
      this._super();

      var errors = Ember.get(this, 'errors') || [];
      var quotaErrors = Ember.get(this, 'primaryResource').validateResourceQuota(Ember.get(this, 'originalModel.resourceQuota.limit'));

      if (quotaErrors.length > 0) {
        errors.pushObjects(quotaErrors);
      }

      Ember.set(this, 'errors', errors);
      return Ember.get(this, 'errors.length') === 0;
    },
    willSave: function willSave() {
      var labels = _objectSpread({}, Ember.get(this, 'primaryResource.labels'));

      if (Ember.get(this, 'scope.currentCluster.istioEnabled')) {
        if (Ember.get(this, 'istioInjection')) {
          labels[ISTIO_INJECTION] = ENABLED;
        } else {
          delete labels[ISTIO_INJECTION];
        }
      }

      Ember.setProperties(this, {
        'beforeSaveModel': Ember.get(this, 'originalModel').clone(),
        'primaryResource.labels': labels
      });
      return this._super.apply(this, arguments);
    },
    didSave: function didSave(pr) {
      var projectId = pr.projectId;

      if (projectId !== Ember.get(this, 'beforeSaveModel.projectId')) {
        return pr.doAction('move', {
          projectId: projectId
        }).then(function (pr) {
          return pr;
        });
      }
    },
    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });

  _exports.default = _default;
});