define("ui/components/settings/landing-page/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PYBhEED8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"landingPage.header\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"radio-button\",null,[[\"selection\",\"value\"],[[24,[\"value\"]],\"ember\"]]],false],[0,\"\\n      \"],[1,[28,\"t\",[\"landingPage.ember\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[7,\"label\",true],[8],[0,\"\\n      \"],[1,[28,\"radio-button\",null,[[\"selection\",\"value\"],[[24,[\"value\"]],\"vue\"]]],false],[0,\"\\n      \"],[1,[28,\"t\",[\"landingPage.vue\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/landing-page/template.hbs"
    }
  });

  _exports.default = _default;
});