define("ui/components/settings/table-rows/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YNYM8+1k",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h2\",true],[8],[0,\"\\n  \"],[1,[28,\"t\",[\"tableCountSelect.header\"],null],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"box\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n    \"],[1,[28,\"new-select\",null,[[\"classNames\",\"optionValuePath\",\"optionLabelPath\",\"content\",\"value\"],[\"form-control\",\"value\",\"value\",[24,[\"tableCounts\"]],[24,[\"selectedCount\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ui/components/settings/table-rows/template.hbs"
    }
  });

  _exports.default = _default;
});